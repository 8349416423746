import React from "react"
import { ReactComponent as Wordmark } from "../images/svgs/wordmark.svg"

const AllSvgs = {
  wordmark: Wordmark,
}

const getIconMarkup = svg => {
  return React.createElement(AllSvgs[svg])
}

function Svg({ svg, variation, className = "", ariaLabel, onClick }) {
  const kebabCaseName = svg.replace(
    /[A-Z]/g,
    letter => `-${letter.toLowerCase()}`
  )

  const standardStyle = `
    Svg Svg--${kebabCaseName} ${className}
    ${!!variation ? `Svg--${kebabCaseName}--${variation}` : ""}
  `
  return (
    <div className={standardStyle} aria-label={ariaLabel} onClick={onClick}>
      {getIconMarkup(svg)}
    </div>
  )
}

export default Svg
