import * as React from "react"

import Seo from "../components/seo"
import Svg from "../components/svg"

const Header = ({ children }) => (
  <h2 className="uppercase font-body font-bold text-sm my-8">{children}</h2>
)

const Subheader = ({ children }) => (
  <h3 className="uppercase text-sm my-4">{children}</h3>
)

const Paragraph = ({ children }) => (
  <p className="font-body text-sm my-4">{children}</p>
)

const IndexPage = () => (
  <>
    <Seo />
    <main className="grid place-items-center p-8 md:p-16">
      <div className="max-w-[975px]">
        <Svg
          svg="wordmark"
          className="text-white w-full"
          ariaLabel="HXOUSE Wordmark"
        />
        <section className="my-16 flex flex-col md:flex-row md:justify-between font-header font-bold uppercase text-sm md:text-lg">
          <h1>Privacy Policy</h1>
          <h1 className="mt-2 md:text-right">Last Updated: March 21, 2022</h1>
        </section>
        <Header>1. General</Header>
        <Paragraph>
          This Privacy Policy (the “Privacy Policy”) describes how OPEN HXOUSE
          LLC, its affiliates and associated film companies (collectively,
          “HXOUSE,” “we,” or “us”), collect, use, and share Personal Information
          about you when you visit our website, www.hxouse.com or any other
          website under our control that links to this Privacy Policy (the
          “Site”) or use any of the services provided on the Site or are
          otherwise described in this Privacy Policy.
        </Paragraph>
        <Paragraph>
          As used in this Privacy Policy, “Personal Information” means any data
          that identifies, relates to or describes you, or is reasonably capable
          of being associated with, or could reasonably be linked to you,
          directly or indirectly.
        </Paragraph>

        <Header>
          2. (A) Collected Personal Information; Sources; Use Information that
          you provide to us
        </Header>

        <Subheader>contact form and comments on the Site</Subheader>

        <Paragraph>
          If you send us questions via the contact form on the Site, the time at
          which you created the comment or message and your email address will
          be stored along with your comment. We will collect such information to
          answer your question and any follow-up questions. This information is
          necessary for us to be able to manage our Site, manage and grow our
          business, and enforce and defend our legal rights.
        </Paragraph>

        <Subheader>Email and other correspondence</Subheader>
        <Paragraph>
          We will collect the Personal Information that you provide to us, such
          as your name, employment information, and contact information, if you
          contact us, such as through phone or email. We use this Personal
          Information to respond to your requests, and to manage the Site and
          our business.
        </Paragraph>

        <Header>2. (B) Information that we collect automatically</Header>
        <Subheader>Cookies</Subheader>
        <Paragraph>
          Cookies are small text files that are stored on your computer and
          saved by your browser. We and our third party providers use cookies on
          parts of the Site. Cookies help make the Site more user-friendly,
          efficient, and secure. The Site may employ both “session cookies,”
          which are automatically deleted after each visit to the Site, and
          other cookies that remain in your device's memory until you delete
          them, making it possible to recognize your device when you next visit
          the Site. You can configure your browser to automatically accept
          cookies under certain conditions or to always reject cookies, or to
          automatically delete cookies when closing your browser. Disabling
          cookies may limit some of the functionality of the Site.
        </Paragraph>

        <Subheader>Google Analytics</Subheader>
        <Paragraph>
          We use Google Analytics, a web analysis service provided by Google, in
          order to better understand your use of the Site and how we can improve
          it. Google Analytics collects information such as how often users
          visit a website, what pages you visit when you do so, and what other
          websites you used prior to coming to such website. Google Analytics
          collects only the IP address assigned to you on the date you visit a
          website, rather than your name or other directly identifying
          information. We do not combine the information collected through the
          use of Google Analytics with your Personal Information. Although
          Google Analytics plants a persistent cookie on your web browser to
          identify you as a unique user the next time you visit a website, the
          cookie cannot be used by anyone but Google. Google Analytics uses its
          cookie(s) and the information Google Analytics collects to track your
          online activities over time and across different websites when you use
          the Site, and other websites. Google\'s ability to use and share
          information collected by Google Analytics about your visits to the
          Site is governed by the Google Analytics Terms of Service and the
          Google Privacy Policy. Google utilizes the data collected to track and
          examine the use of the Site, to prepare reports on its activities, and
          share them with other Google services. To more fully understand how
          Google may use the data it collects on the Site, please review “How
          Google Uses Information From Sites or Apps That Use Our Services”
          (located here).
        </Paragraph>

        <Header>3. Linked Sites and Social Media</Header>

        <Paragraph>
          For your convenience, this Site may contain hyperlinks to other
          websites that are not under our control. We are not responsible for,
          and this Privacy Policy does not apply to, the privacy practices of
          those sites or of any companies that we do not own or control, and we
          do not endorse any of these websites or pages, or their content,
          products or services.
        </Paragraph>
        <Paragraph>
          Any information that you submit to any of our pages on a social media
          platform or by interacting with social media plugins (e.g., the
          Facebook “like button”), if any, on the Site is done at your own risk
          and without any expectation of privacy. We cannot control the actions
          of other users of a social media platform and we are therefore not
          responsible for any information provided on a social media platform or
          website. Please review any such social media providers’ privacy policy
          and terms of use before interacting with any such platform.
        </Paragraph>

        <Header>4. Other Uses of Personal Information</Header>
        <Paragraph>
          In addition to the uses described above, we may use all of the
          categories of Personal Information that we and third party services
          providers collect through the Site to operate and deliver the Site, to
          provide the services offered on the Site, improve the user experience
          on the Site, address Site integrity or security issues, and conduct
          research and analysis to maintain, protect, develop, and improve the
          Site. We may also use Personal Information as necessary to ensure
          compliance with any of our policies, applicable legal or regulatory
          requirements, to enforce and defend our legal rights or any other
          lawful purpose.
        </Paragraph>

        <Header>5. Privacy Rights</Header>
        <Subheader>Do not track requests</Subheader>
        <Paragraph>
          Certain state laws require that we indicate whether we honor “Do Not
          Track” settings in your browser concerning targeted advertising. We
          adhere to the standards set out in this Privacy Policy and do not
          monitor or follow any Do Not Track browser requests.
        </Paragraph>
        <Subheader>Other Data Protection Rights</Subheader>
        <Paragraph>
          Subject to applicable data protection laws, you may have the right to
          access, update, and correct inaccuracies in your Personal Information.
          Please send such requests info@hxouse.com. We may request certain
          Personal Information for the purposes of verifying the identity of the
          individual seeking access to their Personal Information.
        </Paragraph>

        <Header>6. Security and Retention</Header>
        <Subheader>Data Security</Subheader>

        <Paragraph>
          We take commercially reasonable efforts to protect against
          unauthorized access to or use of your Personal Information.
          Nevertheless, no security system is impenetrable. We cannot guarantee
          that information that users of the Site transmit or otherwise supply
          will be totally secure.
        </Paragraph>

        <Subheader>Personal Information Retention</Subheader>
        <Paragraph>
          We retain the Personal Information that we collect where we have an
          legitimate business need to do so, such as, for example, to provide
          the Site or related services, maintain our records, or to comply with
          an applicable legal requirement.
        </Paragraph>

        <Header>7. With Whom Do We Share Information That We Collect?</Header>
        <Paragraph>
          In addition to the disclosures mentioned or described elsewhere in
          this Privacy Policy, we may also disclose Personal Information if we
          become subject to a subpoena or court order, if we are otherwise
          legally required to disclose information, to establish or exercise our
          legal rights, to enforce our Terms of Use, to assert and defend
          against legal claims, if we believe such disclosure is necessary to
          investigate, prevent, or take other action regarding actual or
          suspected illegal or fraudulent activities or potential threats to the
          physical safety or well-being of any person, or for any other lawful
          purpose.
        </Paragraph>
        <Paragraph>
          As HXOUSE grows and develops our business, it is possible that its
          corporate structure or organization might change or that it might
          merge or otherwise combine with, or that it or portions of its
          business might be acquired by, another company. In any such
          transactions, customer information generally is, and should be
          expected to be, one of the transferred business assets. Also, if any
          bankruptcy, liquidation or reorganization proceeding is brought by or
          against us, Personal Information may be considered a company asset
          that may be sold or transferred to third parties.
        </Paragraph>

        <Header>8. Children</Header>
        <Paragraph>
          The Site is intended for a general audience and is not intended for
          use or viewing by children under 13 years of age. We do not knowingly
          collect information from or about children or sell products to
          children under the age of 13. If you are under the age of 13, you must
          ask your parent or guardian to assist you in using the System.
          Furthermore, we may restrict entries to any contests, sweepstakes or
          promotions to entrants who are at least 18 years of age.
        </Paragraph>

        <Header>9. Visiting The System From Outside CANADA</Header>
        <Paragraph>
          If you are visiting the System, or any part thereof, from outside of
          Canada, please be aware that your information may be transferred to,
          stored or processed in Canada. The data protection and other laws of
          the Canada and other countries might not be as comprehensive as those
          in your country, but please be assured that we take steps to protect
          your privacy.
        </Paragraph>

        <Header>10. Changes to our Privacy Policy</Header>
        <Paragraph>
          We may change this Privacy Policy at any time and from time to time.
          The most recent version of the Privacy Policy is reflected by the
          effective date located at the top of this Privacy Policy. All such
          updates will be effective immediately upon notice thereof, which we
          may give by any means, including, but not limited to, by posting a
          revised version of this Privacy Policy or other notice on the Site.
          This Privacy Policy is our notice to you, and does not constitute a
          contract between you and us. The version of this Privacy Policy posted
          on the Site on each respective date you visit the Site shall be the
          Privacy Policy applicable to your access and use of the Site on that
          date. Our electronically or otherwise properly stored copies of this
          Privacy Policy shall be deemed to be the true, complete, valid,
          authentic, and enforceable copy of the version of this Privacy Policy
          which were in force on each respective date you visited the Site.
        </Paragraph>

        <Header>11. Contact Us:</Header>
        <Paragraph>
          If you have any questions or concerns about this privacy policy, or to
          exercise any of the privacy rights described above, please contact us
          at info@hxouse.com.
        </Paragraph>
      </div>
    </main>
  </>
)

export default IndexPage
